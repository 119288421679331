import React from "react";
import {
  ContactSection,
  Page,
  ProjectsSection,
  Seo,
} from "portfolio-theme";

export default function PortfolioPage() {
  return (
    <>
      <Seo title="Federico Zarantonello · Portfolio" />
      <Page>
        <ProjectsSection sectionId="portfolio" heading="Portfolio" />
        <ContactSection sectionId="contact" heading="Contact me" />
      </Page>
    </>
  );
}
